








import * as echarts from 'echarts'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize'

@Component({
  name: 'BarChart'
})

export default class extends mixins(ResizeMixin) {
  @Prop({ required: true }) private chartData!: any
  @Prop({ required: false }) private title!: string
  @Prop({ default: 'chart' }) private className!: string
  @Prop({ default: 'chart' }) private id!: string
  @Prop({ default: '200px' }) private width!: string
  @Prop({ default: '500px' }) private height!: string

  @Watch('chartData', { deep: true })
  private onChartDataChange(value: any) {
    this.setOptions(value)
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  }

  private initChart() {
    this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement)
    this.setOptions(this.chartData)
  }

  private setOptions(chartData: any) {
    if (this.chart) {
      this.chart.setOption({
        title: {
          text: this.title,
          left: 'left',
          textStyle: {
            color: '#41949F'
          }
        },
        grid: {
          left: '200px'
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: chartData.map((item: any) => item.name),
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          axisLabel: { color: '#0d0d0d' },
        },
        series: [
          {
            type: 'bar',
            data: chartData,
            label: {
              show: true,
              position: 'right',
              valueAnimation: true,
              color: '#0d0d0d'
            },
            color:['#41949F', '#81C179', '#E6D27C', '#E6A17C']
          }
          
        ]      
      })
    }
  }
}
