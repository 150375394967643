import request from '@/utils/request'

export const compareVisitsToAssignments = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/compareVisitsToAssignments',
    data
  })

export const getGroupedAssignations = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/getGroupedAssignations',
    data
  })

export const activePropertiesStatus = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/activePropertiesStatus',
    data
  })

export const getGroupedVisits = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/getGroupedVisits',
    data
  })

export const getGroupedClients = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/getGroupedClients',
    data
  })

export const groupPropertiesPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/groupPropertiesPerUser',
    data
  })

export const callsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/callsPerUser',
    data
  })

export const insideOfficeVisitsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/insideOfficeVisitsPerUser',
    data
  })

export const outsideOfficeVisitsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/outsideOfficeVisitsPerUser',
    data
  })

export const incomingRecommendationsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/incomingRecommendationsPerUser',
    data
  })

export const outgoingRecommendationsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/outgoingRecommendationsPerUser',
    data
  })

export const incomingCollaborationsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/incomingCollaborationsPerUser',
    data
  })

export const outgoingCollaborationsPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/outgoingCollaborationsPerUser',
    data
  })

export const activeSearchesPerUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/activeSearchesPerUser',
    data
  })

export const getPanelGroupCounts = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/getPanelGroupCounts',
    data
  })

export const getPropertiesViews = (data: any) =>
  request({
    method: 'post',
    url: '/api/statistics/getPropertiesViews',
    data
  })
