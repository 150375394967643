








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getTimeRangeFromString, parseTime } from '@/utils'
import { TimeRange } from '@/models/TimeRange'

@Component({
  name: 'DateRangeDropdown'
})

export default class extends Vue {
  @Prop({ default: getTimeRangeFromString('last_year') }) private range!: TimeRange
  @Prop({ default: 'last_year' }) private dropdownValue!: string
  @Prop({ default: false }) private withoutCustom!: boolean

  private getDateRangeFromExpr(command: string) {
    this.$emit('range-update', {
      range: getTimeRangeFromString(command),
      dropdownValue: command
    })
  }

  get dropdownTitle() {
    const title = []
    if (this.range.from) {
      title.push(parseTime(new Date(this.range.from || 0), '{y}/{m}/{d}'))
    }
    if (this.range.to) {
      title.push(parseTime(new Date(this.range.to || 0), '{y}/{m}/{d}'))
    }
    return title.join(' - ')
  }
}
